<script>
     export let race;

    // import { onMount, onDestroy } from 'svelte';
    // import  Timer  from "../../../middleware/timer/timer.js";
    // import {formatTime} from "../../../middleware/timer/utils.js";

    // let currentSeconds = 0;

    // const timer = new Timer(race, (seconds) => {
    //   currentSeconds = seconds ;
    // });

    // onMount(() => {
    //   console.log("mount");
    // }); 

    // onDestroy(() => {
    //   console.log("destroy");
    // });


</script>
<div class="card">            
    <div class="card-content">
      <span class="card-title"><a href='results/race?raceId={race.id}'>{race.seriesName} Race {race.raceNumber}</a></span>
      <p>This race is in progress</p>
      <!-- <p>{formatTime(currentSeconds)}</p> -->
      <p>Race type: {race.raceType}</p>
      <div style="text-align: right;">    
    </div>
    </div>
</div>