import { FirebaseClubs } from './index'

const clubsDb = () => {
  
  const findOne = async (clubId) => {
    let doc = await FirebaseClubs.doc(clubId).get();
    if (!doc.exists) return {name: 'Club does not exist', id: ''};
    let club = doc.data() ; 
    club.id = doc.id;  
    return club ; 
  }

  return Object.freeze({
    findOne,
  })
}

const Clubs = clubsDb()

export { Clubs }
