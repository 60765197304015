<script>
  import Menu from '../layout/menu.svelte'
  import { onMount } from 'svelte'
  onMount(async () => {
    var elems = document.querySelectorAll('.collapsible')
    M.Collapsible.init(elems, {})
  })
</script>


  <div id="index-banner" class="blue-grey darken-4">
    <div class="section">
      <div class="container white-text">
        <h1 class="header light-green-text">About</h1>
        <div class="row">
          <h5 class="header col s12 light">What is Sailrace, and why</h5>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="section">
      <ul class="collapsible">
        <li>
          <div class="collapsible-header"><i class="material-icons">question_answer</i>What is Sailrace?</div>
          <div class="collapsible-body">
            <span>Sailrace is a mobile app for Android and iOS devices which allows you to run average lap timing dinghy races,
              commonly run for club racing in the UK. It runs both fleet racing and pursuit racing (where the slowest boat starts first and is chased).
            </span>
          </div>
        </li>
        <li>
          <div class="collapsible-header"><i class="material-icons">question_answer</i>What problem does it solve?</div>
          <div class="collapsible-body">
            <p>
              There are a number of benefits to using an App rather than pen and paper to record races. Not just that
              this is 2022!
            </p>
            <p>
              The app deals with timing the race and provides a number of features to make recording sail numbers easy.
              Results are immediate, even whilst the race is in progress as we can score each lap and not just the
              finishing laps. Data entry is eliminated. We can gain insights as to how each lap of our race went and not
              just the final lap, all providing topic of discussion for post-race drinks in the bar after sailing!
            </p>
          </div>
        </li>
        <li>
          <div class="collapsible-header">
            <i class="material-icons">question_answer</i>My club uses Sailwave, can we continue using that?
          </div>
          <div class="collapsible-body">
            <p>
              Yes, the app is designed to work with Sailwave, and provides a CSV export function on the results page
              which produced a file compatible with Sailwave. Results can then be imported in a few clicks, with no
              manual data entry.
            </p>
            <p>
              On the roadmap is the ability to upload directly to this website where we can provide more detailed
              breakdown of how a competitors races went (i.e. did they win on the last lap).
            </p>
          </div>
        </li>
        <li>
          <div class="collapsible-header">
            <i class="material-icons">question_answer</i>What is my tablet dies, will my results be lost?
          </div>
          <div class="collapsible-body">
            <p>
              The app saves all data to its local database immediately. So data is not retained “in memory” to be saved
              later which count be lost on battery failure. If the tablet battery goes flat, anything recorded to that
              point can be recovered. 
            </p>
            <p>
              A battery bank is a cheap and sensible investment if running races from a boat.
            </p>
            <p>
              The app now supports redundancy, where two apps can connect together. So if you lose one, you can carry on with the other.
            </p>
          </div>
        </li>
        <li>
          <div class="collapsible-header">
            <i class="material-icons">question_answer</i>Can I use an iOS device (iPad etc)?
          </div>
          <div class="collapsible-body">
            <p>Yes!</p>
            <p>
              The application is available both on Android (version 8 and above) and iOS (running version 12 and above),
              and we have run it on devices ranging from iPhone 6 to the newer iPads. Whilst your club may choose to
              invest in a tablet, having it available on both platforms lets race officers become familiar with it at
              home, and perhaps even those who have not had a go at race office duty yet will feel comfortable trying
              it!
            </p>
          </div>
        </li>
        <li>
          <div class="collapsible-header">
            <i class="material-icons">question_answer</i>What is the minimum recommended hardware?
          </div>
          <div class="collapsible-body">
            <p>
              The app works best on at least 7inch screen sizes. It does not need to be super powerful - an £80 tablet
              from Amazon would work fine.
            </p>
          </div>
        </li>
        <li>
          <div class="collapsible-header">
            <i class="material-icons">question_answer</i>Why didn’t you create a web app?
          </div>
          <div class="collapsible-body">
            <p>
              It needs to run offline as in a committee boat internet access may be limited. It’s critical software to
              the races (we don’t want angry sailors afterwards because the internet failed), so a dedicated app makes
              sense. Ability to run offline also saves battery.
            </p>
          </div>
        </li>
        <li>
          <div class="collapsible-header"><i class="material-icons">question_answer</i>What are the alternatives?</div>
          <div class="collapsible-body">
            <p>
              For club racing in the UK, pen and paper still seems to rule. Absolutely key is that it can work offline
              and must be really easy to select boats as they come past.
            </p>
            <p>
              Also key is that at no stage should the race-officer be left stuck. So dealing with the 'edge case'
              where sail numbers can not be seen (time needs to be captured and then assigned), or dealing with boats on the course who 
              have not signed on yet has been a key consideration. We don't believe there is another app out there which does 
              this.
            </p>
          </div>
        </li>
        <li>
          <div class="collapsible-header">
            <i class="material-icons">question_answer</i>What if we have an “unknown racer” during the race?
          </div>
          <div class="collapsible-body">
            <p>
              If someone forgot to sign on, or you have an unexpected sail number on the course, the app deals with
              this, allowing you to either “tally on” someone during the race, or add a completely new unrecognized sail
              number. The critical part is to get the lap timings, the rest can be sorted out afterwards. The app is
              designed to have the options there when you need them, and not leave you stuck needing to revert back to
              paper.
            </p>
          </div>
        </li>
        <li>
          <div class="collapsible-header"><i class="material-icons">question_answer</i>Can it do pursuit races?</div>
          <div class="collapsible-body">
            <p>Yes, this was added in version 1.1.0. Simply choose Pursuit race when creating the race. There is then a 
              screen where you can enter target race time and it will work out start time offsets for you. When running pursuit races 
              the timer works in reverse, counting down to 0. The boat on lead on the water at 0 is the winner.
            </p>
          </div>
        </li>
        <li>
          <div class="collapsible-header"><i class="material-icons">question_answer</i>What are our liabilities?</div>
          <div class="collapsible-body">
            <p>
              This is free software and it is your responsibility to test that it meets your needs and works for you. I will of course test the software and work to ensure there are zero bugs and it is written to a high quality. Reliability is absolutely key. There is a standard liability notice normally found on software products below which basically says it in more legal jargon.
            </p>
            <p>
              THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS IS" AND ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT HOLDER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
            </p>
          </div>
        </li>
        <li>
          <div class="collapsible-header"><i class="material-icons">question_answer</i>What we ask of you?</div>
          <div class="collapsible-body">
            <p>
              The app is free to use with no obligations. If you like the app, a review on the Play or App store would
              be great, and please share with other sailing contacts.
            </p>
            <p>
              There is a <a href='https://facebook.com/sailraceapp'>Facebook page</a> where announcements of new features will be posted, so please like that to
              stay in the loop. Feel free to comment or get in touch if you have any questions or ideas you’d like in
              there too.
            </p>
          </div>
        </li>
        <li>
          <div class="collapsible-header"><i class="material-icons">question_answer</i>Tech stack</div>
          <div class="collapsible-body">
          
            <p>The app is written in <a href="https://flutter.dev/" alt="flutter">Flutter</a>.</p>
            <p>The backend (Cloud) is using <a href="https://firebase.google.com/">Google Firebase</a>.</p>
            <p>This website is in <a href="https://svelte.dev/">Svelte</a> and <a href="https://materializecss.com/">Materialize</a>.</p>
            <p>Redundancy is implemented using <a href="https://grpc.io/">gRPC</a></p>
            <p>Graphics for this website and elsewhere have in part been created with <a href="https://previewed.app/">previewed.app</a>. Superb.</p>
           
          </div>
        </li>
      </ul>
      
    </div>
  </div>

