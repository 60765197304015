import { Firestore } from '../../config/firebase';

function formatTime(seconds) {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
}

function transformResult(data) {
  let laps = data['laps'];
  let lastLap = laps.length > 0 ? laps[laps.length - 1] : '0/0/0';
  let triplet = lastLap.split('/');
  data.elapsed = parseInt(triplet[0]);
  data.elapsedStr = formatTime(data.elapsed);
  data.corrected = parseFloat(triplet[1]);
  data.correctedStr = formatTime(Math.round(data.corrected));
  if (!data.bce) data.bce = 0;
  if (!data.bcr) data.bcr = 0;
  data.bceStr = formatTime(Math.round(data.bce));
  return data;
}

const transformResults = (snap) => {
  let results = snap.docs.map(doc => transformResult(doc.data()));

  results.sort((a, b) => a.corrected == 0.0 ? 1 :
    b.corrected == 0.0 ? -1 :
      a.corrected < b.corrected ? -1 : 0);

  let maxFinishers = results.filter(r => r.code == 'FIN').length;

  results.map((r, i) => r.code == 'FIN' ? r.pos = i + 1 :
    r.pos = maxFinishers + 1);

  return results;
}

const raceDb = () => {
  const queryResults = async (raceId) => {
    let snap = await Firestore.collection('races/' + raceId + '/results')
      .where('talliedOn', '==', true)
      .limit(100)
      .get();
    return transformResults(snap);
  }

  const subscribeResults = async (raceId, callback) => {
    let snap = await Firestore.collection('races/' + raceId + '/results')
      .where('talliedOn', '==', true)
      .limit(100);

    return snap.onSnapshot({
      next: (snap) => {
        callback(transformResults(snap));
      }
    })

  }

  return Object.freeze({
    queryResults,
    subscribeResults
  })
}

const Race = raceDb()

export { Race }

