import Login from '../../views/public/login/index.svelte'
import Signup from '../../views/public/signup/index.svelte'
import PublicIndex from '../../views/public/home/index.svelte'
import PublicLayout from '../../views/public/layout/index.svelte'
import NotFound from '../../views/404.svelte'
import Faq from '../../views/public/faq/index.svelte'
import Privacy from '../../views/public/faq/privacy.svelte'
import Docs from '../../views/public/docs/index.svelte'
import Cloud from '../../views/public/faq/cloud.svelte'
import Redundancy from '../../views/public/faq/redundancy.svelte'
import Terms from '../../views/public/faq/terms.svelte'
import Results from '../../views/public/results/index.svelte'
import RaceResults from '../../views/public/results/race_results.svelte'
import Delete from '../../views/public/faq/delete.svelte'

const publicRoutes = [
  {
    name: '/',
    component: PublicIndex,
  },
  
  { name: 'login', component: Login, layout: PublicLayout },
  
  //{ name: 'signup', component: Signup, layout: PublicLayout },
  
  { name: 'faq', component: Faq, layout: PublicLayout },
  { name: 'docs', component: Docs, layout: PublicLayout },
  { name: '404', component: NotFound, layout: PublicLayout },
  { name: 'privacy', component: Privacy, layout: PublicLayout },
  { name: 'cloud', component: Cloud, layout: PublicLayout },
  { name: 'redundancy', component: Redundancy, layout: PublicLayout },
  
  { name: 'terms', component: Terms, layout: PublicLayout },
  { name: 'results', component: Results, layout: PublicLayout },
  { name: 'results/race', component: RaceResults, layout: PublicLayout },

  { name: 'delete', component: Delete, layout: PublicLayout },


  
]

export { publicRoutes }
