<script>
import { Race } from "../../../middleware/database/race";
import { Races } from "../../../middleware/database/races";
  import { onMount, onDestroy } from 'svelte';
    let results = [];
    let urlParams = new URLSearchParams(window.location.search);
    let raceId = urlParams.get('raceId');
    let race = {};
    let cancel;
    onMount(async () => {
      race = await Races.findOne(raceId); 
      cancel = await Race.subscribeResults(raceId, (newResults) => {
        results = newResults;
      })
    });
    onDestroy(() => {
      cancel();
    });
  </script>
<main>
    <div class="container">
      <h5>{race.seriesName} Race {race.raceNumber}</h5>
      <p>Completed: {race.completedDt?.toLocaleString('en-UK')}</p>
      <table class="striped responsive-table">
        <thead>
          <tr>
            <td>Pos</td>
            <td>Sail No</td>
            <td>Class</td>
            <td>Helm</td>
            <td>Crew</td>
            <td>PN</td>
            <td>Laps</td>
            <td>Elasped</td>
            <td>Corrected</td>
            <td>BCE</td>
            <td>BCR</td>
          </tr>
        </thead>
        <tbody>        
          {#each results as r}
            <tr>
              <td>{r.pos}</td>
              <td>{r.sailNumber}</td>
              <td>{r.boatClass}</td>
              <td>{r.helm}</td>
              <td>{r.crew}</td>
              <td>{r.handicap}</td>
              <td>{r.laps.length}</td>
              <td>{r.elapsedStr}</td>
              <td>{r.correctedStr}</td>
              <td>{r.bceStr}</td>
              <td>{r.bcr.toFixed(2)}</td>
            </tr>
          {/each}
        </tbody>              
      </table>
    </div>
  </main>