<script>
  import Menu from '../layout/menu.svelte'
  import { onMount } from 'svelte'
  onMount(async () => {
    var elems = document.querySelectorAll('.collapsible')
    M.Collapsible.init(elems, {})
  })
</script>

<style>
  .ios-badge {
    height: 54px; 

  }
  .play-badge {
    height: 80px;
  }
</style>

<div>
    <div id="index-banner" class="blue-grey darken-4" >
      <div class="section">
        <div class="container white-text">        
          <h1 class="header light-green-text">Sailrace cloud</h1>
          <div class="row">
            <h5 class="header col s12 light">
              Instant race results
            </h5>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="container">
  <h5>How it works</h5>
    <p>Sailing clubs create a <b>free</b> account through the <strong>dinghy race timer</strong>
      app, and will be allocated a <strong>Club Id</strong>. 
    </p><p>The Club Id can then be 
        used by club members in the Sailrace competitor app view the race results instantly they 
        are published. Basic results can also be viewed online using
        the club id, for an example checkout <a rel="nofollow" href='results?clubId=saAghck1rBVO4gMkOfYL'>the Sailrace example club.</a>        
      </p>
      <h5>Where to get the Sailrace competitor app</h5>
      <p>Competitors can download and install the app for Android or iOS using the links below. It's free and they won't need to create
        an account. Simply having the Club Id is enough for them to see the races for that club.
      </p>
        <span class='valign-wrapper'>
          <a href='https://play.google.com/store/apps/details?id=app.sailrace'>
            <img class='play-badge' src='/images/google-play-badge.png' alt='Download Sailrace on the Play store'/> 
          </a>     
         
          <a href='https://apps.apple.com/gb/app/sailrace/id1555879374'>
            <img class='ios-badge' src='/images/ios-appstore-badge.svg' alt='Download Sailrace on the App Store'/> 
          </a>
        </span>
    
      <h5>Questions</h5>
      <p>Got any questions? Get in touch, or of check out some of the common ones below. To get in touch email sailrace.app@gmail.com or post on the <a href='https://www.facebook.com/sailraceapp/'>Sailrace 
        facebook page.</a></p>
      <div class="section">
        <ul class="collapsible">
          <li>
            <div class="collapsible-header"><i class="material-icons">question_answer</i>
              Sailrace vs Sailrace dinghy race timer - what is the difference?</div>
            <div class="collapsible-body">
              <span>Sailrace dinghy race timer is for timing the races, used by the race office team. Sailrace (no timer) is for viewing the results.
                After all, what is the point of capturing all this extra detail, if no one can see it! With this combo, races can be timed,
                calculated and shared within seconds of the final boat finishing! (subject to protests:)

              </span>
            </div>
          </li>
          <li>
            <div class="collapsible-header"><i class="material-icons">question_answer</i>
              What is a 'Club Id'?</div>
            <div class="collapsible-body">
              <span>
                This is a semi-private key which can be used to connect to your club. You would enter this in the Sailrace 
                app, either by scanning the Qr Code, or manually to see a feed of the latest race results.
                You may connect with up to 10 clubs in the Sailrace app. The main view of the app is a 
                feed where you see the latest results from any of your clubs immediately after they are published.
              </span>
            </div>
          </li>
          <li>
            <div class="collapsible-header"><i class="material-icons">question_answer</i>
              What benefit does this give?</div>
            <div class="collapsible-body">
              <span>
                Well time is a key one. You don't have to wait for results to be entered into another application. But you also get access to all your lap times and not just the finish. So with the 
                charting features built into Sailrace you get much deeper insight into how you race ran.
                
              </span>
            </div>
          </li>
          <li>
            <div class="collapsible-header"><i class="material-icons">question_answer</i>
              I have other questions?</div>
            <div class="collapsible-body">
              <span>
                Please let us know, 
                
              </span>
            </div>
          </li>
          
        </ul>
      </div>

</div>