<script>
    export let race;
</script>
<div class="card">            
    <div class="card-content">
      <span class="card-title">{race.seriesName} Race {race.raceNumber}</span>
      <p>This race has not yet started</p>
      <p>Scheduled: {race.completedDt.toLocaleString('en-UK')}</p>
      <p>Race type: {race.raceType}</p>
    </div>
</div>