<script>
  import { onMount } from 'svelte'  
  onMount(async () => {
    var elems = document.querySelectorAll('.parallax');
    M.Parallax.init(elems, {});
  });
</script>

<style>
.badge {
  height: 80px;
}
.badge-ios{
  height: 54px;
}
.bigger {
  font-size: 1.3em;
}
  .parallax-container {
      height: inherit
  }
</style>

<div id="index-banner" class="parallax-container" >
  <div class="section">    
    <div class="container white-text" style="margin-bottom: 2em;">      
      <h1 class="header white-text">Sailrace dinghy race timer</h1>
      <h2 class="header col s12 light bigger">
      A modern approach to manage, time and score sailing races.  
      
      </h2>
      <span class='valign-wrapper'>
      <a href='https://play.google.com/store/apps/details?id=com.sailscore.app'>
      <img class='badge' src='/images/google-play-badge.png' alt='Download from the Play Store'/> 
      </a>
      <a href='https://apps.apple.com/us/app/sailrace-dinghy-race-timer/id1547876986'>
        <img class='badge-ios' src='/images/ios-appstore-badge.svg' alt='Download from the App Store'/> 
      </a>  
      </span>
      <a href="https://www.buymeacoffee.com/timc4662A" target="_blank">
          <img src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" 
          alt="Buy Me A Coffee" style="height: 54px !important;width: 215px !important; margin-left:10px;" >
      </a>
    </div>
    <div class="parallax">
      <img src="/images/hero.jpeg" alt="dinghy racing">
    </div>    
  </div>
</div>
