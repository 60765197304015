<script>
    import { onMount } from 'svelte'
    onMount(async () => {
      var elems = document.querySelectorAll('.materialboxed')
      M.Materialbox.init(elems, {})
    })
  </script>

<style>
    .ios-badge {
      height: 54px; 
  
    }
    .play-badge {
      height: 80px;
    }
  </style>

<div class="container">
    <div class="row">
        <div class="col s12 m6">

            <img src="images/home/app_timer.png" width="300" class="materialboxed"
            alt="Sailrace timing sheet"/>

        </div>
        <div class="col s12 m6">
            <h3>Average lap race timing</h3>
            <p>Use Sailrace to manage the start sequence for the race.</p>
            <p>Sailrace then provides an easy to use timing sheet - simply hit 'LAP' when sailors complete a lap.</p>
            <p>But as we all know this can sometimes get a bit frantic, so the app provides a bunch of features to help.</p>
            <p><strong>Capture times for later assignment</strong> - really the most important thing is to get the times, we can sort out who they belonged to later.</p>
            <p><strong>Filter the list by sail number</strong> - A built-in search makes it really quick and easy with no keyboard or popups. If someone had not tallied on, you can add them. If someone is missing from the list, you can add them.</p>
            <p><strong>Pre-select competitors on the run</strong> - you know, when you have breathing space.</p>
            <p>The main goal - never leave the race officer stuck - it's supposed to help you!</p>
            <p><b>NEW:</b> <a href="/redundancy">Redundancy mode</a> -  Connect two apps for realtime redundancy.</p>
        </div>
    </div>

    <div class="row">
        
        <div class="col s12 m6">

            <img src="images/home/app_results.png" width="300" class="materialboxed"
            alt="Sailrace results"/>

        </div>
        <div class="col s12 m6">
            <h3>Instant results</h3>
            <p>
                Results are calculated instantly, even whilst the race is in progress.
                Gone are the days of going home not knowing who won! 
            </p>
            <p>
                With a single click, results can be published <a href='cloud'>to the cloud</a> and sailors can get results
                direct to their phones instantly the race is finished or see the basic results online using
                the clubs id. For example check-out the <a rel="nofollow" href='results?clubId=saAghck1rBVO4gMkOfYL'>sailrace sample race</a>.
            </p>
            <p>
                One click CSV export into a format compatible with Sailwave means all that
                manual data entry is a thing of the past. And you can keep using Sailwave (or whatever else) for 
                the formal results if you like.
            </p>
            <p>
                The Sailrace database can easily be exported with a single click to a 
                cloud drive, emailed or otherwise shared for backups or just to allow others
                access to the results. 
            </p>
        </div>
    </div>

    <div class="row">
        
        <div class="col s12 m6">

            <img src="images/home/live_mode.png" width="300" class="materialboxed"
            alt="Sailrace results streamed live"/>

        </div>
        <div class="col s12 m6">
            <h3>Live Streaming</h3>
            <p>
                Timings can even be streamed in realtime via Sailrace Cloud to the companion app. This app 
                is aimed at participants, spectators and parents who want to keep track of the racing. 

            </p>

            <p>Now they don't have to wait even until the results are published, they can see who is winning in realtime!</p>
            
            <p>The companion app can be downloaded on both Play and Apple stores.</p>

            <span class='valign-wrapper'>
                <a href='https://play.google.com/store/apps/details?id=app.sailrace'>
                  <img class='play-badge' src='/images/google-play-badge.png' alt='Download Sailrace on the Play store'/> 
                </a>     
               
                <a href='https://apps.apple.com/gb/app/sailrace/id1555879374'>
                  <img class='ios-badge' src='/images/ios-appstore-badge.svg' alt='Download Sailrace on the App Store'/> 
                </a>
              </span>

              <p>This is also a great 
                way to backup results incase, you know, you drop your phone in the sea!</p>
            
        </div>
    </div>

    <div class="row">
        <div class="col s12 m6">

            <img src="images/home/app_chart.png" width="300" class="materialboxed"
            alt="Sailrace post race charts"/>

        </div>
        <div class="col s12 m6">
            <h3>Detailed Après-Race</h3>
            <p>There is so much more you can do with results when you have access to all data, and not just the final lap times.</p>
            <p>Sailrace aims to provide detailed insights into how your race progressed. You can 
                see charts of corrected times, positions for each lap, and even pick who is displayed
                on the charts.
            </p>
            <p>You could get some of this information from GPS, but that does not always show how your laps compared to others.
            </p>
            <p>With the companion app, this information is also avaliable to participants.</p>
        </div>
    </div>

    <div class="row">
        <div class="col s12 m6">

            <img src="images/home/pursuit_race_start.png" width="300" class="materialboxed"
            alt="Sailrace post race charts"/>

        </div>
        <div class="col s12 m6">
            <h3>Pursuit race mode</h3>
            <p>As well as fleet mode, Sailrace has the ability to run pursuit races.</p>
            <p>'Tally On' those who are sailing and decide a race time, the app will calculate race 
                start time offsets for each of the 
                classes and allow this information to be exported for sharing. 
            </p>
            <p>Then simply start the race. The app provides real-time countdowns for all
                classes. You know the question - "How long to my start?". Well now you can answer it!
            </p>
            
        </div>
    </div>
</div>