<script>  
  export let params
  params = null
  export let currentRoute
  currentRoute = null
  import Menu from '../layout/menu.svelte'
  import Hero from './hero.svelte'
  import MainFeatures from './main_features.svelte'
  import Footer from '../layout/footer.svelte'
  import Intro from './intro.svelte';
  import Detail from './detail.svelte';
import Redundancy from './redundancy.svelte';
</script>

<style>
  .ios-badge {
    height: 54px; 

  }
  .play-badge {
    height: 80px;
  }
</style>


<div class="app">  
  <Menu />
  <main>
    <Hero />
    <Intro/>
    <MainFeatures />  

    <Detail/>
    <Redundancy/>

    <br/><br/>
    <div class="container">    
      <div class="row">
        <div class="col s12 m6 center-align">
          <a class="btn-large waves-effect" href="/faq">More Information</a>    
          <br/>
          <br/>
        </div>
        <div class="col s12 m6 center-align">
          <a class="btn-large waves-effect" href="/docs">Quick Start Guide</a>
          <br/>
        </div>
      </div>
    </div>
    <div class="container">
      <span class='valign-wrapper'>
        <a href='https://play.google.com/store/apps/details?id=com.sailscore.app'>
          <img class='play-badge' src='/images/google-play-badge.png' alt='Play store'/> 
        </a>     
        <a href='https://apps.apple.com/us/app/sailrace-dinghy-race-timer/id1547876986'>
          <img class='ios-badge' src='/images/ios-appstore-badge.svg' alt='Download on the App Store'/> 
        </a>     
      </span>
    </div>

    
    <br/>
    <br/>
  </main>
  <Footer />
</div>

