<script>
    import { onMount } from 'svelte'
    onMount(async () => {
      var elems = document.querySelectorAll('.materialboxed')
      M.Materialbox.init(elems, {})
    })
</script>

<style>
    .detail-image {
      max-width: 100%;
      max-height: 100vh;
      margin: auto;
    }

    h6 {
        font-weight: 600;
    }
  </style>

<div class="blue-grey darken-4 white-text">  
<div class="container">
  <div class="row">
    <div class="col s12 center-align">
        <h3>Redundancy</h3>
    </div>
  </div>
</div>
<div>
  <div class="container">
        <p>With the redundancy enabled in settings, you can connect two apps together and data is synced in realtime to the backup device.</p>
        <p>This can give you confidence you won't lose results either due to hardware failiure, or simply dropping your iPad over the side!</p>
        <p><a href="/redundancy">More information</a></p>
          <br/>
          <br/>
      </div>
</div>
</div>



