<script>
  import Menu from '../layout/menu.svelte'
  import { onMount } from 'svelte'
  onMount(async () => {
    var elems = document.querySelectorAll('.collapsible')
    M.Collapsible.init(elems, {})
  })
</script>

<style>
  .ios-badge {
    height: 54px; 

  }
  .play-badge {
    height: 80px;
  }
</style>

<div>
    <div id="index-banner" class="blue-grey darken-4" >
      <div class="section">
        <div class="container white-text">        
          <h1 class="header light-green-text">Redundancy</h1>
          <div class="row">
            <h5 class="header col s12 light">
              Connect two apps for realtime backup
            </h5>
          </div>
        </div>
      </div>
    </div>
</div>
<div class="container">
  <img class="detail-image materialboxed" src="/images/home/redundancy.png" alt="Sailrace redunancy mode" style="width: 600px" />
  
  <h5>The idea</h5>
  <p>Simply run two apps on different devices. They just need to be on the same network, but a personal hotspot works too.</p>
  <p>You connect your primary device to your backup. Perhaps this is your phone in your pocket.</p>
  <p>If the worst comes to the worst and you lose your primary, you can either carry on with the backup or at least have a copy of the results.</p>
  <p>This works even when using a personal hotspot to connect devices, so you don't need a full blown network. Although there are plenty of battery powered network hubs which would work too.</p>
  
  <h5>How to enable</h5>
  <img class="detail-image materialboxed" src="/images/home/redundancy_settings.png" alt="Sailrace redunancy mode" style="height: 300px" />
  
  <p>Enable through the <b>Settings > Redundancy</b></p>
  <p>IP information is encoded in a QrCode, so simply scan the backup device from your primary and they should connect. The icon will turn green when connected.</p>
  <p>You will notice an additional icon in the app bar. Click this for two options:</p>
  <ol>
    <li>Holding Mode - This is a screen you can use on your backup device which keeps the app in the foreground but uses minimal power. It will for example reduce screen brightness to min. Hold the button to exit holding mode.</li>
    <li>Sync - This will copy all data from primary to backup. It won't delete data on backup.</li>
  </ol>

  <h4>Opensource server app</h4>
  <p>The gRPC service definition file is open source and can be found here <a href='https://github.com/timc4662/sailraceserver'>https://github.com/timc4662/sailraceserver</a></p>
  <p>This repo also contains a simple server application that listens for messages from the app.</p>
  <p>It would be possible to write a controller which takes the timings produced by the 
    app. It could back them up to a file, switch lights on, beeps horns, or display live timings on a monitor in the club house.
  </p>
  
<br/>
<br/>
</div>