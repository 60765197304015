<script>
  import { Clubs } from '../../../middleware/database/clubs';
  import { Races } from '../../../middleware/database/races';
  import FinishedCard from './finished_card.svelte'
  import NotstartedCard from './notstarted_card.svelte'
  import { onMount, onDestroy } from 'svelte';
  import InprogressCard from './inprogress_card.svelte'

  let urlParams = new URLSearchParams(window.location.search);
  let clubId = urlParams.get('clubId')
  let races = []; 
  let clubName = '';    
  let cancelFunc = null;

  onMount(async () => {
    let club = await Clubs.findOne(clubId); 
    clubName = club.name ; 
    
    cancelFunc = await Races.subscribeAll(clubId, (newRaces) => {
      races = newRaces;
    }); 

  });
  
  onDestroy(() => {
    if (cancelFunc != null) {
      cancelFunc();
    }
  }); 

 
</script>
<main>
    <div class="container"> 
        <h2 class="center-align">Latest results for {clubName}</h2>
        {#each races as race}
          {#if race.raceState === 'Finished'}
            <FinishedCard bind:race={race}/>
          {:else if race.raceState === 'NotStarted'}
            <NotstartedCard bind:race={race}/>
          {:else}
            <InprogressCard bind:race={race}/>
          {/if}  
        {/each}
        <!-- <div class="center-align" style="margin-bottom: 10vh;">
          <button type="button" on:click={loadMore} class="btn-small waves-effect blue darken-4 center-align">Load more results</button>
        </div> -->
    </div>
  </main>