

// const firebaseConfig = {
//   apiKey: "AIzaSyBfY_2sd3jAYFEhgYKTmZ3iwzeyrDVFj6Q",
//   authDomain: "sail-score.firebaseapp.com",
//   databaseURL: "https://sail-score.firebaseio.com",
//   projectId: "sail-score",
//   storageBucket: "sail-score.appspot.com",
//   messagingSenderId: "971165037928",
//   appId: "1:971165037928:web:dd02b4853eac532aac92dd",
//   measurementId: "G-LWDRPHDKRC"
// };

const firebaseConfig = {
  apiKey: "AIzaSyAz6C5f2tEOVBzP0u9bep4IOwkhsyj6B1w",
  authDomain: "sailscore-dev.firebaseapp.com",
  projectId: "sailscore-dev",
  storageBucket: "sailscore-dev.appspot.com",
  messagingSenderId: "450108926905",
  appId: "1:450108926905:web:b438fb29158829efe0a468"
};

export { firebaseConfig }
