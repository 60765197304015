import { FirebaseEmployees, FirebaseRaces } from './index'

function transformRace(id, data) {
  let race = data;
  race.id = id; 
  race.completedDt = race.completed.toDate();
  return race;
}

const racesDb = () => {

  const subscribeAll = async (clubId, raceHandler) => {
    let query = FirebaseRaces. 
      limit(10). 
      where('clubId', 'array-contains', clubId).
      orderBy('completed', 'desc'); 
    
    let cancel = query.onSnapshot({next: (snap) => {
      let races = snap.docs.map( v => transformRace(v.id, v.data())); 
      raceHandler(races);
    }, error: (err) => {
      console.error(err);
    }},);
    return cancel;
  }
  
  const findAll = async (clubId) => {
    let query = FirebaseRaces.
      limit(10).
      where('clubId', 'array-contains', clubId).
      orderBy('completed', 'desc'); 
    let snap = await query.get();   
    let races = snap.docs.map( v => transformRace(v.id, v.data())); 
    return races; 
  }

  const findOne = async raceId => {
    return transformRace(raceId, (await FirebaseRaces.doc(raceId).get()).data());
  }

  return Object.freeze({
    findAll,
    subscribeAll,
    findOne
  })
}

const Races = racesDb()

export { Races }
