<script>
    export let race;
</script>
<div class="card">            
    <div class="card-content">
      <span class="card-title"><a href='results/race?raceId={race.id}'>{race.seriesName} Race {race.raceNumber}</a></span>
      <p>This race has finished</p>
      <p>Completed: {race.completedDt.toLocaleString('en-UK')}</p>
      <p>Race type: {race.raceType}</p>
      <ul>
        <li>First: {race.first}</li>
        <li>Second: {race.second}</li>
        <li>Third: {race.third}</li>    
      </ul>
      <div style="text-align: right;">    
      <a class="btn-small waves-effect blue darken-4" href="https://europe-west1-sail-score.cloudfunctions.net/getResults?raceId={race.id}"><i class="material-icons left">cloud_download</i>Download CSV</a>        
    </div>
    </div>
</div>