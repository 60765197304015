<script>
  import { onMount } from 'svelte'
  onMount(async () => {
    var elems = document.querySelectorAll('.materialboxed')
    M.Materialbox.init(elems, {})
  })
</script>

<div>
  <div id="index-banner" class="blue-grey darken-4">
    <div class="section">
      <div class="container white-text">
        <h1 class="header light-green-text">Quick start</h1>
        <div class="row">
          <h5 class="header col s12 light">A short guide to get you started</h5>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="section">
    <h5>Create a series</h5>
    <div class="divider" />
    <p>
      Sailrace groups competitors and races under series. An example series is provided, but it is easy to create your
      own. A series would normally contain a full list of everyone who may sail in it. This provides a 'library' of competitors
      from which you can easily pick via a 'Tally On' feature in a race.
    </p>
  </div>
  <div class="section">
    <h5>Import competitors</h5>
    <div class="divider" />
    <p>There are three ways to load competitors into a series:</p>
    <ol>
      <li>Import them from a file</li>
      <li>Import them from another series</li>
      <li>Manually create them</li>
    </ol>
    <div class="row">
      <div class="col s12 m6">
        <p>To import from a file, prepare the data into the format below, using Excel or Google sheets, and save as CSV format.</p>
        <p>
          SailNo,Class,Helm,Crew,PY<br />
          2839,RS AERO 7,Sherman Hepp,1065<br />
          3180,RS AERO 7,Alice Houze,1065<br />
          2415,RS AERO 7,Armanda Strong,1065<br />
          1558,RS 200,Lesia Kloster,Carolann Pavon,1046
        </p>
        <a href='/competitors.csv' target='_blank'>Here's an example file</a>
        <p>
          Place the file somewhere accessible to your device (Google Drive works perfectly). Then simply pick the file when inside the app to
          have it loaded. Note that the file must have the header row shown, and the columns must be in the order shown.
        </p>
        <p>You can <a href='https://www.rya.org.uk/racing/Pages/portsmouthyardstick.aspx'>download the latest handicaps and classes from the RYA</a> in PDF and Excel formats</p>
      </div>
      <div class="col s12 m6">
        <img
          class="materialboxed"
          width="300"
          src="/images/docs/import-from-file.png"
          alt="Import competitors from file" />
      </div>
    </div>
  </div>

  <div class="section">
    <h5>Run the race</h5>
    <div class="divider" />
    <p>
      Races are created inside the series. These are created with a schedule time, so it is possible to
      pre-load the clubs racing calendar into the app.
    </p>
    <div class="row">
      <div class="col s12 m4">
        <p>Process for running the race:</p>
        <ol>
          <li>Use Timing Sheet to tally on competitors (1)</li>
          <li>Choose between 5,4,1 - 3,2,1 - 60s,30s,10s countdown time in the options</li>
          <li>Choose a 'Pre-start Period'. This leaves you free to handle flags, signals etc</li>
          <li>Start the countdown sequence. The app displays time to next signal and has optional audio alarms</li>
          <li>
            'Go'! The app will display 'All Clear', 'Individual Recall', or 'General Recall' buttons. Choose one.
            <p>If 'Individual Recall' is choosen, pick OCS competitors via the 'OCS' button on the Timing Sheet</p>
            <p>Click 'Finish OCS' when done</p>
          </li>
          <li>Click 'Lap' as the competitors complete laps (2)
          <p>
            If a bunch of boats are approaching the end of lap at the same time, it is helpful to pre-select them into a
            shorter list to make finding them easier. Click "SEL" to add to the pre-select list when you think you know
            the order and then 'LAP' when they actually finish the lap.
          
          </p></li>
          <li>
            After the desired race duration is up (or close) click 'START FINISHING' to enter finish mode. This is where
            you would shorten the course (normally two horns + flag). The 'LAP' button changes to 'FIN'.
          </li>
          <li>Click 'FIN' as competitors pass the finish line</li>
          <li>
            After all competitors are finished, click 'COMPLETE RACE'. If any competitors are not finished the app warns
            and will mark them as DNF.
          </li>
          <li>Click 'RESULTS' to view the results immediately (3)</li>
        </ol>
      </div>
      <div class="col s12 m4">
        <img class="materialboxed" width="300" src="/images/screenshots/1.jpeg" alt="Import competitors from file" />
      </div>
      <div class="col s12 m4">
        <img class="materialboxed" width="300" src="/images/screenshots/7.jpeg" alt="Import competitors from file" />
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <p>
          (1) Tallying creates a shorter list of commpetitors than may be in the series, making searching
          during a race easier. Don't worry though, competitors can be tallied on when the race is in progress.
        </p>
        <p>
          (2) The Timing Sheet is by default sorted by Predicted Time. This uses PY initially and then lap times to sort
          the competitors, so it is likely that competitors will be presented in the list, in the order on the water (at
          least for lap 2.
        </p>
        <p>
          (3) If you need to edit the results you can do this via the 'Edit' button. You can even come back later and edit the 
          results, even after closing and restarting the app as everything is saved.
        </p>
      </div>
    </div>
  </div>

  <div class="section">
    <h5>Export results</h5>
    <div class="divider" />
    <div class="row">
      <div class="col s12 m6">
        <p>
          Results are presented immediately in the app, however they can easily be exported to a CSV file, using a format
          compatible with Sailwave.
        </p>
        <p>Click "Export Results" and save the results to the cloud (Google Drive for example) or Email</p>
        <p>Open the series in Sailwave. Click File &gt; Import results from CSV file...</p>
        <p>Pick the file and continue through the wizard</p>
        <img class="materialboxed" width="400" src="/images/docs/sailwave.png" alt="Sailwave" />
        <p>The mapping should be detected automatically. Ensure it is configured as shown</p>
        <p>Click next and finish and the results will now be in Sailwave - easy!</p>
      </div>
      <div class="col s12 m6">
        <img class="materialboxed" width="300" src="/images/screenshots/15.jpeg" alt="Import competitors from file" />
      </div>
      <div class="col s12 m6">
        <img class="materialboxed" width="300" src="/images/docs/sailwave-results.png" alt="Results in sailwave" />
      </div>
    </div>
  </div>
</div>
