<script>
    import { onMount } from 'svelte'
    onMount(async () => {
      var elems = document.querySelectorAll('.materialboxed')
      M.Materialbox.init(elems, {})
    })
</script>

<style>
    .detail-image {
      max-width: 100%;
      max-height: 100vh;
      margin: auto;
    }

    h6 {
        font-weight: 600;
    }
  </style>

<div class="container">
  <div class="row">
    <div class="col s12 center-align">
        <h3>All the options you could need</h3>
      <img class="detail-image materialboxed" src="/images/home/detail.png" alt="Sailrace timing sheet in detail" />
    </div>
  </div>
</div>
<div>
  <div class="container">
    <div class="row">
      <div class="col s6">
        <h6>Elapsed time</h6>
        <p>The current race time from start, or time remaining for pursuit races.</p>

        <h6>Captured times</h6>
        <p>Timepoints added with the 'capture time button'. Timepoints can then be assigned by clicking them. Useful if you can't spot sail numbers during rounding!</p>

        <h6>Sail number filter</h6>
        <p>The filter currently applied to the list.</p>

        <h6>Competitor details</h6>
        <p>Double tap or long press here to edit result during race.</p>
    
        <h6>Lap count</h6>
        <p>Current laps completed by this sailor. Colour changes each lap.</p>

        <h6>Elapsed and corrected times</h6>
        <p>Time for each lap in elapsed and corrected time.</p>
    
        <h6>Sort order buttons</h6>
        <p>Applies a sort to the list. Predicted makes a guess at who will be up next - recommended default.</p>
    
        <h6>Sail number filter</h6>
        <p>Super fast sail number filter. Enter a few numbers to filter the list.</p>

      </div>

      <div class="col s6">
        <h6>Capture time button</h6>
        <p>Captures a timepoint for later assignment. A panic button!</p>

        <h6>Add new competitor</h6>
        <p>Shown when sail number filter is in place if no exact match. Allows a new competitor to be added whilst race is in progress.</p>

        <h6>LAP button</h6>
        <p>The all important LAP button. Simply click when a boat completes a lap.</p>

        <h6>SEL (preselect) button</h6>
        <p>Adds boat to a secondary list allowing some 'prep-work' when the boats are on the last leg and you have time.</p>

        <h6>Tally on</h6>
        <p>When a filter is in place, competitors in the series but not tallied on will be shown greyed out. They can be tallied on mid race if needed via this button.</p>

        <h6>Clear filter</h6>
        <p>Clears the sail number filter. Filter automatically cleared on LAP.</p>

        <h6>Clear preselection button</h6>
        <p>Removes the boat from the preselection list. Removed automatically when LAP is clicked.</p>

      </div>
    </div>
  </div>
</div>


