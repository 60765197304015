import firebase from 'firebase/app'
import 'firebase/firebase-firestore'
import 'firebase/firebase-auth'
import 'firebase/firebase-functions'
import 'firebase/firebase-storage'
import 'firebase/app-check';

import { firebaseConfig } from './settings'

firebase.initializeApp(firebaseConfig)

const Firestore = firebase.firestore()
const Auth = firebase.auth()
const Functions = firebase.app().functions('europe-west1')
const Storage = firebase.storage()

const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
appCheck.activate(
  '6LdmWTglAAAAAH1O94lRNEHRVyxTD2fkM4FASOEe',

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  true);

export { Firestore, Auth, Functions, Storage }
